import "./index.scss";
import React from "react";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";
import ReactJson from "react-json-view";
import geteImxLoggEnvConfData from "./modules/geteImxLoggEnvConfData";
import setenvConfDataWithParams from "../configuration/modules/setenvConfData";
import { useToasts } from "react-toast-notifications";

import changeImxLoggerAppName from "./modules/changeImxLoggerAppName";
import connectToImxLogger from "./modules/connectToImxLogger";
import disconnectImxLogger from "./modules/disconnectImxLogger";
import disableErrorsAndDebugsLogs from "./modules/disableErrorsAndDebugsLogs";
import enableErrorsAndDebugsLogs from "./modules/enableErrorsAndDebugsLogs";
import enableErrorsLogs from "./modules/enableErrorsLogs";
import disableErrorsLogs from "./modules/disableErrorsLogs";
import disableDebugLogs from "./modules/disableDebugLogs";
import enableDebugLogs from "./modules/enableDebugLogs";
import enableLogOnly from "./modules/enableLogOnly";
import disableLogOnly from "./modules/disableLogOnly";
import unsetDisableAll from "./modules/unsetDisableAll";
import setDisableAll from "./modules/setDisableAll";

import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";

function ImxLogger() {
  const [envConfData, setEnvConfData] = React.useState({});
  const { addToast } = useToasts();

  const setenvconfValues = async () => {
    try {
      await setenvConfDataWithParams({
        newEnvconf: envConfData,
      });
      addToast("Configuration saved successfully", {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
      addToast("error while saving configuration", {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  };

  const getenvconfValues = React.useCallback(async () => {
    try {
      const envConfDataResponse = await geteImxLoggEnvConfData();
      setEnvConfData(envConfDataResponse?.content);
    } catch (error) {
      console.error("error ===>", error);
    }
  }, []);

  const handleChangeAppName = React.useCallback(async (appName) => {
    try {
      const res = await changeImxLoggerAppName({ appName });
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnectToImxLogger = React.useCallback(async () => {
    try {
      const res = await connectToImxLogger();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisconnectToImxLogger = React.useCallback(async () => {
    try {
      const res = await disconnectImxLogger();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisableErrorsAndDebugLogs = React.useCallback(async () => {
    try {
      const res = await disableErrorsAndDebugsLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnableErrorsAndDebugLogs = React.useCallback(async () => {
    try {
      const res = await enableErrorsAndDebugsLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnableErrorsLogs = React.useCallback(async () => {
    try {
      const res = await enableErrorsLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisableErrorsLogs = React.useCallback(async () => {
    try {
      const res = await disableErrorsLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisableDebugLogs = React.useCallback(async () => {
    try {
      const res = await disableDebugLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnableDebugLogs = React.useCallback(async () => {
    try {
      const res = await enableDebugLogs();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnableLogOnly = React.useCallback(async () => {
    try {
      const res = await enableLogOnly();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisbleLogOnly = React.useCallback(async () => {
    try {
      const res = await disableLogOnly();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnsetDisableAll = React.useCallback(async () => {
    try {
      const res = await unsetDisableAll();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlesetDisableAll = React.useCallback(async () => {
    try {
      const res = await setDisableAll();
      getenvconfValues();
      addToast(res?.content?.result, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getenvconfValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardApp>
      <Sidebar>
        <div className="imx-logger-container">
          <p className="title">Actions without reconnection needed : </p>
          <div className="actions-buttons-container">
            <form
              className="input-button"
              onSubmit={(e) => {
                e?.preventDefault();
                handleChangeAppName(e?.target[0]?.value || "");
              }}
            >
              <input
                type="text"
                defaultValue={envConfData?.MX_LOGGER_APP_NAME}
              />
              <button type="submit">Change App Name</button>
            </form>
            <button
              className="action-button"
              onClick={handleConnectToImxLogger}
            >
              Connect To IMXLOGGER
            </button>
            <button
              className="action-button"
              onClick={handleDisconnectToImxLogger}
            >
              Disconnect from IMXLOGGER
            </button>
            <button
              className="action-button"
              onClick={handleDisableErrorsAndDebugLogs}
            >
              Disable ERRORS/DEBUG Logs
            </button>
            <button
              className="action-button"
              onClick={handleEnableErrorsAndDebugLogs}
            >
              Enable ERRORS/DEBUG Logs
            </button>
            <button className="action-button" onClick={handleDisableErrorsLogs}>
              Disable ERRORS Logs
            </button>
            <button className="action-button" onClick={handleDisableDebugLogs}>
              Disable DEBUG Logs
            </button>
            <button className="action-button" onClick={handleEnableDebugLogs}>
              Enable DEBUG Logs
            </button>
            <button className="action-button" onClick={handleEnableErrorsLogs}>
              Enable ERRORS Logs
            </button>

            <button className="action-button" onClick={handleEnableLogOnly}>
              Enable LogOnly
            </button>
            <button className="action-button" onClick={handleDisbleLogOnly}>
              Disable LogOnly
            </button>

            <button className="action-button" onClick={handleUnsetDisableAll}>
              unset Disable_ALL
            </button>
            <button className="action-button" onClick={handlesetDisableAll}>
              set Disable_AL
            </button>
          </div>
          <div className="edit-config-title">
            <p>Edit config and reconnect to IMX LOGGER : </p>
            <div className="edit-config-buttons-container">
              <button onClick={setenvconfValues}>
                <SaveIcon />
              </button>
              <button onClick={getenvconfValues}>
                <CachedIcon />
              </button>
            </div>
          </div>

          <p>
            !. Change config and reconnect to imxLogger in the top container
            "Connect To IMXLOGGER" .
          </p>
          <p>!. Make sure to save changes before reconnecting .</p>

          <div className="d-flex p-2 mt-4">
            <ReactJson
              src={envConfData}
              onEdit={(values) => {
                setEnvConfData(values.updated_src);
              }}
              onDelete={(values) => {
                // setEnvConfData(values.updated_src);
              }}
              onAdd={(values) => {
                // setEnvConfData(values.updated_src);
              }}
            />
          </div>
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default ImxLogger;
