import "./index.scss";

import React, { useState, useEffect } from "react";

import { useSelector, useStore } from "react-redux";

import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { RebootServer, UpdateMaintenance } from "../services";
import { useToasts } from "react-toast-notifications";
import updateMaintenance from "../services/updateMaintenance";
import AlertDialog from "../../installer/components/global-components/alert-dialog/AlertDialog";

function DashboardHeader() {
  const navigate = useNavigate();

  let store = useStore();
  const { addToast } = useToasts();
  const [maintenanceEnabled, setMaintenance] = useState();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await updateMaintenance();
      if (data && data?.success) {
        setMaintenance(data?.content?.ENABLE_MAINTENANCE);
      }
    })();
  }, []);
  const closeDialog = () => {
    setShowDialog(false);
  };
  const openDialog = () => setShowDialog(true);
  const handleLogout = () => {
    localStorage.removeItem("authorization");
    store.dispatch({
      type: "dashboard/updateLoginStatus",
      payload: {
        isLoggedin: false,
      },
    });
    navigate("/");
  };
  const isTransactionServer = useSelector(
    (state) => state?.entities?.dashboard?.isTransactionServer
  );
  return (
    <div
      id="DashboardHeader"
      className="DashboardHeader"
      data-testid="DashboardHeader"
    >
      <div className="header-content">
        <img src={logo} alt="maxapss logo" />
        <div className="header-content-right">
          <div>
            <input
              type="checkbox"
              id="checkTransactionServer"
              name="checkTransactionServer"
              value={isTransactionServer}
              checked={isTransactionServer}
            />
            <label htmlFor="checkTransactionServer">
              is Transaction Server
            </label>
          </div>

          <button
            className={
              maintenanceEnabled === true
                ? "maintenanceEnabled-btn"
                : "maintenanceDisable-btn"
            }
            data-testid="logout-btn"
            onClick={async () => {
              const data = await UpdateMaintenance(true);
              if (data && data?.success) {
                setMaintenance(data?.content?.ENABLE_MAINTENANCE);
              }
            }}
          >
            {maintenanceEnabled === true
              ? "Maintenance Enabled"
              : "Maintenance Disabled"}
          </button>
          <button
            className="logout-btn"
            data-testid="logout-btn"
            onClick={() => openDialog()}
          >
            Reboot
          </button>
          <button
            className="logout-btn"
            data-testid="logout-btn"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <AlertDialog
        dialogHeader={"Do you want to reboot server?"}
        show={showDialog}
        CloseDialog={closeDialog}
      >
        <React.Fragment>
          <div className="confirm-uninstall">
            <button
              className="cancel-btn"
              onClick={(e) => {
                e.stopPropagation();
                closeDialog();
              }}
              data-testid="No"
            >
              <span className="dialog-btn-text">No</span>
            </button>
            <button
              className="confirm-btn"
              onClick={(e) => {
                e.stopPropagation();
                closeDialog();
                RebootServer(addToast);
              }}
              data-testid="Yes"
            >
              <span className="dialog-btn-text">Yes</span>
            </button>
          </div>
        </React.Fragment>
      </AlertDialog>
    </div>
  );
}

export default DashboardHeader;
