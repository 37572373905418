export const services = Object.freeze({
  NODE_ENDPOINT_GET_ENVCONF: "sys/getImxLoggerEnvconf",

  NODE_ENDPOINT_DISCONNECT_IMXLOGGER: "logger/disconnectLogger",

  NODE_ENDPOINT_CONNECT_IMXLOGGER: "logger/connectLogger",

  NODE_ENDPOINT_ENABLE_LOGS_IMXLOGGER: "logger/enableLogs",

  NODE_ENDPOINT_DISABLE_LOGS_IMXLOGGER: "logger/disableLogs",

  NODE_ENDPOINT_ENABLE_ERRORS_LOGS_IMXLOGGER: "logger/enableErrorLogs",

  NODE_ENDPOINT_DISABLE_ERRORS_LOGS_IMXLOGGER: "logger/disableErrorLogs",

  NODE_ENDPOINT_ENABLE_DEBUG_LOGS_IMXLOGGER: "logger/enableDebugLogs",

  NODE_ENDPOINT_DISABLE_DEBUG_LOGS_IMXLOGGER: "logger/disableDebugLogs",

  NODE_ENDPOINT_UNSET_DISABLE_ALL_IMXLOGGER: "logger/unsetDisbaleAll",
  NODE_ENDPOINT_SET_DISABLE_ALL_IMXLOGGER: "logger/setDisbaleAll",

  NODE_ENDPOINT_UNSET_LOG_ONLY_IMXLOGGER: "logger/unsetLogOnly",

  NODE_ENDPOINT_SET_LOG_ONLY_IMXLOGGER: "logger/setLogOnly",

  NODE_ENDPOINT_CHANGE_APP_NAME_IMXLOGGER: "logger/changeLoggerAppName/",
});
